import React from 'react';
import './showcaseText.scss';
import headshot from '../../img/showcase-head.jpg';
import ReactBootstrap, { Row } from 'react-bootstrap';

const ShowcaseText  = () =>{
    return(
        <Row id='showcase-parent'>
            <div  className='showcase-all'>
            <Row><h1 id="top-heading">WELCOME!</h1></Row>
            <Row><div className="headshot">
                    <img id="pro-pic" src={headshot} alt="headshot" />
                </div>
            </Row>
            <Row><div id="showcase-text"><p>Software Engineer</p></div></Row>
            <Row>
            <div id="showcase-grp-btns">
                    <a href="https://www.linkedin.com/in/jude-gyimah-31aa97a1/" className="btn-header">More About Me</a>
                </div>
            </Row>
            </div>
        </Row> 
    );
}

export default ShowcaseText;