import React from 'react';
import ReactBootstrap, { Nav, Navbar, NavDropdown, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';

const Navigation = () =>{
    return (
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
            <Nav.Link href="#">Home</Nav.Link>
            <Nav.Link href="#proj">Projects</Nav.Link>
            <Nav.Link href="#stk">Stacks</Nav.Link>
        </Nav>
        </Navbar.Collapse>
        </Container>
        </Navbar>
    );
}

export default Navigation;