import React from 'react';
import './projects.scss';
import ReactBootstrap, { Card, Row, Button } from 'react-bootstrap';

const Projects = () =>{
    return(
        <div id="proj">
         <br />
            <p style={{textAlign: 'center', color: 'white'}}>
                <Card.Title>Projects</Card.Title>
            </p>
        
     <Row className='prow'>
     <div class="col-sm">
        <Card bg={'light'} style={{ width: '22rem' }} className="mb-2">
        <Card.Title>Vattn AB Water Testing Solution</Card.Title>
            <Card.Body>
            <Card.Text>
            A web solution aimed streamlining water testing and water test results interpretation in Sweden.
            </Card.Text>
            </Card.Body>
        </Card>
        </div>
        <div class="col-sm">
        <Card bg={'light'} style={{ width: '22rem' }} className="mb-2">
        <Card.Title>Variability Management Library</Card.Title>
            <Card.Body>
            <Card.Text>
                A Variability Management Library for Robotic Systems That Implements Variation Points Based On Binding Time and Binding Mode
            </Card.Text>
            <Button href="https://sites.google.com/view/sled-dsl/home?authuser=0" target='_blank'>See Project</Button>
            </Card.Body>
        </Card>
        </div>
        <div class="col-sm">
       
        </div>
</Row>
</div>
    );
}

export default Projects;