import './App.scss';
import '../components/footer/Footer';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import AboutMe from '../components/about/AboutMe';
import Projects from '../components/projects/Projects';
import Skills from '../components/skills/Skills';
//import Particles from 'react-particles-js';
import ReactBootstrap, { Col, Row, Container } from 'react-bootstrap';

const App = () => {
  return (
    <Container>
    <Row>
      <Col></Col>
      <Col xs={12}>
          <Header />
              <main>
                <AboutMe />
                <Projects />
                <Skills />
              </main>
            <Footer />
      </Col>
      <Col></Col>
    </Row>
    </Container>
  );
}

export default App;
