import React from 'react';
import portrait from '../../img/about.jpg';
import './about.scss';
import ReactBootstrap, { Card, Row } from 'react-bootstrap';

const AboutMe = () =>{
    return(
        <div id="proj">
         <br />
            <p style={{textAlign: 'center', color: 'white'}}>
                <Card.Title as="h5">Who Am I?</Card.Title>
            </p>
        <div id="stk">
        <Row className="justify-content-center">
        <Card style={{ width: '66rem', height: '15rem'}} >
        <Card.Body>
            <Card.Text>
            <p>My name is Jude Gyimah and as a Backend Software Engineer at heart. I am a dedicated, motivated individual that relished challenges with a high degree of accountability. I am in search of a career that is exciting, 
focuses on my growth as a professional while working in a high-demand yet world-class environment. I have experience in 
building  robust high-quality microservices and products that have both high performance and reliability. 
I am also knowledgeable in relevant areas such as React.js, Vue.js, Spring framework, SOAP, and USSD application development.</p>
            </Card.Text>
        </Card.Body>
        </Card>
    
    </Row>
    </div>
    </div>
    );
}

export default AboutMe;