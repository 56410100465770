import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import ReactBootstrap, {Row} from 'react-bootstrap';


const Footer = () =>{
    return (
    <footer>
        <div className="soc-med-icons">
            <a href="https://github.com/jaythagod"><FontAwesomeIcon icon={ faGithub } size="2x" /></a>
            <a href="https://www.linkedin.com/in/jude-gyimah-31aa97a1/"><FontAwesomeIcon icon={ faLinkedin } size="2x" /></a>
        </div>
        <div className="copyright">@2020</div>
    </footer>
    );
}

export default Footer;