import React from 'react';
import './skills.scss';
import ReactBootstrap, { Card, Row } from 'react-bootstrap';

const Skills = () =>{
    return(
        <div id="stk">
            <br />
            <p style={{textAlign: 'center', color: 'white'}}>
                <Card.Title>Stacks</Card.Title>
            </p>
      
        <Row className='srow'>
        <div class="col-sm">
        <Card bg={'dark'} text={'white'} style={{ width: '22rem' }} className="mb-2">
        <Card.Header><strong>Java Stack</strong></Card.Header>
            <Card.Body>
            <Card.Text>
                <ul style={{listStyle: 'none'}}>
                    <li>React.js + Typescript</li>
                    <li>Spring Boot, Maven / Gradle, RabbitMQ</li>
                    <li>Postgresql / MySQL /MongoDB</li>
                </ul>
            </Card.Text>
            </Card.Body>
        </Card>
        </div>
        <div class="col-sm">
        <Card bg={'dark'} text={'white'} style={{ width: '22rem' }} className="mb-2">
        <Card.Header><strong>PHP Stack</strong></Card.Header>
            <Card.Body>
            <Card.Text>
                <ul style={{listStyle: 'none'}}>
                    <li>Nuxt.js + Vue.js</li>
                    <li>Lumen(Laravel), RabbitMQ</li>
                    <li>MySQL + MongoDB</li>
                </ul>
            </Card.Text>
            </Card.Body>
        </Card>
        </div>
        <div class="col-sm">
    <Card bg={'dark'} text={'white'} style={{ width: '22rem' }} className="mb-2">
            <Card.Header><strong>Robotics Stack</strong></Card.Header>
                <Card.Body>
                <Card.Text>
                    <ul style={{listStyle: 'none'}}>
                        <li>Rospy</li>
                        <li>Roscpp</li>
                        <li>Ubuntu</li>
                    </ul>
                </Card.Text>
                </Card.Body>
    </Card>
    </div>
    </Row>
    </div>
    );
}

export default Skills;