import React from 'react';
import './header.scss';
import BgImage from '../bg-image/BgImage';
import Navigation from '../navigation/Navigation';
import ShowcaseText from '../showcase-text/ShowcaseText';

const Header = () =>{
    return (
    <header> 
        <Navigation />
        {/*<BgImage />*/}
        <ShowcaseText />
    </header>
    );
}

export default Header;